import useSWR from "swr"
import { useCsrfToken } from "./useCsrfToken"
import { fetchGraphqlQuery } from "common/lib/fetchGraphqlQuery"

export const useGraphqlQuery = <T>(
  query: string | null,
  variables?: Record<string, string | number | boolean>,
) => {
  const { token } = useCsrfToken()
  const shouldFetch = token && query
  const cacheKey = [query, token, variables]
  const {
    data,
    error,
    isLoading: loading,
  } = useSWR<T>(
    shouldFetch ? cacheKey : null,
    ([query, token, variables]) =>
      fetchGraphqlQuery(
        query,
        {
          "X-CSRF-Token": token,
        },
        variables,
      ),
    {},
  )

  return { loading, loaded: !!data && !loading, error, data }
}
